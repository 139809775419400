<script setup>
import {reactive, watch} from "vue";

const props = defineProps({
  number: {
    type: Number,
    required: true,
  }
});

const state = reactive({
  currentNumber: props.number,
  interval: null,
});

watch(() => props.number, () => {
  clearInterval(state.interval);

  if(props.number === state.currentNumber) {
    return;
  }

  state.interval = setInterval(() => {
    let change = (props.number - state.currentNumber) / 5;
    change = change > 0 ? Math.ceil(change) : Math.floor(change);
    state.currentNumber += change;
    if (state.currentNumber === props.number) {
      clearInterval(state.interval);
    }
  }, 50);
});
</script>
<template lang="pug">
| {{ state.currentNumber.toLocaleString('ru') }}
</template>
