<script setup>
import {watch} from "vue";
import Shares from "@/Partials/Shares.vue";
import GameLayout from "@/Layouts/GameLayout.vue";
import {CheckIcon} from '@heroicons/vue/24/solid'
import {useStore} from "vuex";

defineProps();

const store = useStore();
const player = $computed(() => store.state.player || {});
const config = $computed(() => store.state.config || {});

let type = $ref('Specials');
let available = $ref({
  special: false,
  level: false,
  ref: false,
});

watch(() => store.state.player, () => {
  available.level = false;
  available.ref = false;

  for (let index in config.levels) {
    const level = config.levels[index];

    if (!level.reward) {
      continue;
    }

    if (player.shares >= level.from && !player.rewards['LEV' + index]) {
      available.level = true;
      break;
    }
  }

  for (let index in config.ref_rewards) {
    const ref_reward = config.ref_rewards[index];

    if (!ref_reward.reward) {
      continue;
    }

    if (player.ref_count >= ref_reward.count && !player.rewards['REF' + index]) {
      available.ref = true;
      break;
    }
  }
}, { immediate: true });

const claimReward = (reward_id) => {
  axios.post(route('game.claim-reward'), {
    reward_id
  })
};

</script>
<style lang="scss" scoped>
.tasks {
  padding: 20px 20px 0;
  height: 100%;
}

.sharess {
  margin-bottom: 20px;
}

.types {
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  display: flex;
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;

  > button {
    flex: 1;
    border-radius: 10px;
    padding: 15px 5px;
    border: none;
    background: none;

    &.current {
      background: rgba(255, 255, 255, 0.05);
    }

    &.available {
      .text {
        position: relative;

        &:after {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background: #F17744;
          position: absolute;
          top: -2px;
          right: -13px;
        }
      }
    }
  }
}

.tasks-list {
  overflow-y: auto;
  position: absolute;
  bottom: 130px;
  left: 0;
  right: 0;
  top: 240px;
  padding: 0 20px;
}

.task {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  text-align: left;
  padding: 10px 40px 10px 10px;
  margin-bottom: 11px;
  position: relative;
  display: block;

  .elem {
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 50px;
    padding-left: 70px;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .price {
    font-size: 14px;
    padding-left: 28px;
    background: url("../../img/coin_mini2.png") no-repeat left center;
    background-size: contain;
    font-weight: 500;
  }

  .claim-reward {
    padding: 4px 15px;
    font-size: 13px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .check {
    width: 20px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    background: radial-gradient(43.52% 43.52% at 52.76% 51.11%, #91C759 0%, #559B0C 100%);
    border-radius: 10px;
    padding: 3px;
    stroke: #fff;
    stroke-width: 2;
  }

  &.mission {
    .elem {
      background-image: url("../../img/task-default.png");
    }

    &.socials {
      .elem {
        background-image: url("../../img/socials.png");
      }
    }

    &.premium {
      .elem {
        background-image: url("../../img/premium.png");
      }
    }

    &.instagram {
      .elem {
        background-image: url("../../img/instagram.png");
      }
    }
  }

  &.ref {
    .elem {
      background-image: url("../../img/refs2.png");
      //background-position: 15px center;
    }
  }

  &.level {
    .elem {
      background-position: left center;
      background-size: auto 60px;

      @for $i from 0 to 11 {
        &.level_#{$i} {
          background-image: url('../../img/level2_80/#{$i}.png');
        }
      }
    }
  }

  &.disabled {
    opacity: 0.9;
    filter: grayscale(100%);

    .claim {
      display: none;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
<template lang="pug">
GameLayout
  .tasks
    Shares.sharess

    .types
      button(:class="{ current : type === 'Specials', available: available.special}", v-on:click="type = 'Specials'")
        span.text Specials
      button(:class="{ current : type === 'Levels', available: available.level }", v-on:click="type = 'Levels'")
        span.text Levels
      button(:class="{ current : type === 'Refs', available: available.ref }", v-on:click="type = 'Refs'")
        span.text Refs

    transition
      .tasks-list(v-if="type === 'Specials'")
        RouterLink.task.mission(v-for="mission in config.missions", :to="{ name: 'mission', params: { id: mission.id } }", :class="mission.tag ?? ''")
          .elem(:style="{ backgroundImage: mission.icon ? 'url(' + mission.icon + ')' : null }")
            .title {{ mission.title }}
            .price {{ mission.reward.toLocaleString('ru') }}
            CheckIcon.check(v-if="player.missions?.[mission.id]?.finished_at")

    transition
      .tasks-list(v-if="type === 'Levels'")
        .task.level(v-for="(level, index) in config.levels", v-show="level.reward", :class="{disabled: (!level.reward || player.rewards['LEV' + index])}")
          .elem(:class="'level_' + index")
            .title {{ level.name }}
            .price {{ level.reward.toLocaleString('ru') }}
            button.button.claim-reward(v-on:click="claimReward('LEV' + index)", :disabled="player.shares < level.from") Claim

    transition
      .tasks-list(v-if="type === 'Refs'")
        .task.ref(v-for="(ref_reward, index) in config.ref_rewards", v-show="ref_reward.reward", :class="{disabled: !ref_reward.reward || player.rewards['REF' + index]}")
          .elem
            .title Invite {{ ref_reward.count }} Friends
            .price {{ ref_reward.reward.toLocaleString('ru') }}
            button.button.claim-reward(v-on:click="claimReward('REF' + index)", :disabled="player.ref_count < ref_reward.count") Claim
</template>
