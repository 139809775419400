import axios from 'axios';
import store from './store';
import router from './router';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(response => {
  if (response.data?.success) {
    store.commit('success', response.data.success);
  }
  if (response.data?.player) {
    store.commit('player', response.data.player);
  }
  if (response.data?.config) {
    store.commit('config', response.data.config);
  }
  return response;
}, error => {
  if (error.response?.status === 401 && router.currentRoute.value.name !== 'login') {
    store.commit('player', null);
    //redirect to login
    router.push({ name: 'login', query: { redirect: router.currentRoute.value.fullPath } });
  }
  else {
    store.commit('error', error.response?.data?.message ?? 'Unknown error');
  }

  return Promise.reject(error);
});

window.axios = axios;

