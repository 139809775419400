<script setup>
import {onMounted, onUnmounted} from "vue";
import Shares from "@/Partials/Shares.vue";
import GameLayout from "@/Layouts/GameLayout.vue";
import {useStore} from "vuex";
import SmoothCounter from "@/Components/SmoothCounter.vue";
import DailyReward from "@/Components/DailyReward.vue";

const store = useStore();
let player = $computed(() => store.state.player);
const config = $computed(() => store.state.config);
const sprint = $computed(() => store.state.player?.boosts?.sprint?.till > (Date.now() / 1000));
let touched = $ref(false);
let layout = $ref(null);

let taps = 0;
let shares = 0;
let tapsSendedAt = 0;
let tapsInterval;

onMounted(() => {
  makeOverflow(10);
  window.onresize = () => {
    if (document.hidden) {
      makeOverflow(0);
    }
    else {
      makeOverflow(10);
    }
  };
  document.onvisibilitychange = () => {
    if (document.hidden) {
      makeOverflow(0);
    }
    else {
      makeOverflow(10);
    }
  };

  tapsInterval = setInterval(tapsTick, 500);
});

onUnmounted(() => {
  makeOverflow(0);
  window.onresize = null;
  document.onvisibilitychange = null;

  clearInterval(tapsInterval);
});

function makeOverflow(overflow) {
  document.body.style.marginTop = `${overflow}px`;
  document.body.style.height = overflow > 0 ? (window.innerHeight + overflow + 'px') : 'auto';
  document.body.style.paddingBottom = `${overflow}px`;
  window.scrollTo(0, overflow);
}

const sleep = ms => new Promise(r => setTimeout(r, ms));

const touchstart = (e) => {
  touched = true;
  if(Telegram.WebApp.initData) {
    Telegram.WebApp.HapticFeedback.impactOccurred("light");
  }

  const touches = (e instanceof TouchEvent) ? e.changedTouches : [{clientX: e.clientX, clientY: e.clientY}];
  const tapped = touches.length;
  let tap_level = player.tap_level;

  if (player.energy < (tapped * tap_level)) {
    return;
  }

  if (sprint) {
    tap_level *= config.boosts.sprint.multiplier;
  }

  player.shares += tapped * tap_level;

  if (!sprint) {
    player.energy -= tapped * tap_level;
  }

  taps += tapped;
  shares += tapped * tap_level;

  if(touches) {
    for (let i = 0; i < touches.length; i++) {
      const elem = document.createElement('div');
      elem.style.position = 'absolute';
      elem.style.left = (touches[i].clientX - 15) + 'px';
      elem.style.top = (touches[i].clientY - 40) + 'px';
      elem.style.opacity = '1';
      elem.style.fontSize = '30px';
      elem.style.fontWeight = 'bold';
      elem.style.userSelect = 'none';
      elem.innerHTML = '+' + tap_level;

      layout.appendChild(elem);

      fadeOutAndBubbleUp(elem);
    }
  }
};

const dummy = () => {
};

const touchend = (e) => {
  touched = false;
};

const fadeOutAndBubbleUp = async (elem) => {
  while (elem.style.opacity > 0) {
    elem.style.opacity -= 0.01;
    elem.style.top = parseInt(elem.style.top) - 3 + 'px';

    await sleep(10);
  }
  elem.remove();
}

function tapsTick() {
  if ((Date.now() - tapsSendedAt > 3000) && taps > 0) {
    const sendTaps = taps;
    taps = 0;
    shares = 0;

    axios.post(route('game.taps'), {
      taps: sendTaps,
      time: Date.now(),
    }).then(() => {
      player.shares += shares;
    });

    tapsSendedAt = Date.now();
  }
}

</script>
<style lang="scss" scoped>
.index {
  padding: 20px 0 0;
  height: 100%;

  .username {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: rgba(255, 255, 255, 0.57);
    position: absolute;
    bottom: 190px;
    width: 100%;
  }

  .index-shares {
    z-index: 1;
    position: relative;
  }
}

.coin-outer {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  left: 0;
  padding: 130px 0 210px;

  .coin-inner {
    position: relative;
    height: 100%;
  }

  .glow {
    position: absolute;
    background: url("../../img/coin3_430.png") no-repeat center center;
    background-size: contain;
    width: 100%;
    height: 100%;
    filter: blur(20px);
    left: 0;
    top: 0;
    z-index: -1;
  }

  .coin {
    background-color: transparent;
    background-image: url("../../img/coin3_430.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    margin: 0;
    padding: 0;
    user-select: none;

    &.sprint {
      transform: scale(1);
      animation: pulse 1s infinite;
    }

    &.touched {
      transform: scale(0.98);
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

.energy {
  position: absolute;
  bottom: 130px;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  z-index: 2;

  .energy-value {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
    background: url("../../img/lightning.webp") no-repeat left center;
    background-size: 8px;
    display: inline-block;
    padding-left: 15px;
    font-weight: 600;
  }

  .energy-bar {
    width: 100%;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
    padding: 4px;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }

  .energy-bar-fill {
    background: linear-gradient(270.04deg, #FFC632 -119.89%, #F38015 99.97%);
    height: 100%;
    border-radius: 10px;
  }
}

</style>
<template lang="pug">
GameLayout(@touchmove.prevent.stop="dummy")
  .index(ref="layout")
    .coin-outer
      .coin-inner
        .glow
        button.coin(
          @mousedown.prevent.stop="touchstart",
          @mouseup.prevent.stop="touchend",
          @touchstart.prevent.stop="touchstart",
          @touchmove.prevent.stop="dummy",
          @touchend.prevent.stop="touchend",
          :class="{sprint: sprint, touched: touched && !sprint}"
        )

    Shares.index-shares

    .username
      span(v-if="player.username") @{{ player.username }}
      span(v-else) {{ [player.first_name, player.last_name].join(' ').trim() }}

    .energy
      .energy-value
        span.current
          SmoothCounter(:number="player.energy")
        | &nbsp;/&nbsp;
        span {{ (player.energy_level * 500).toLocaleString('ru') }}
      .energy-bar
        .energy-bar-fill(:style="{width: (player.energy / (player.energy_level * 500) * 100 + '%' )}")

  DailyReward
</template>
