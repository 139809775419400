<script setup>
const env = import.meta.env.MODE;
let isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

let isWithinWebApp = !!Telegram.WebApp.initData;

</script>
<style lang="scss" scoped>
.not-mobile {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 50px 30px;

  .title {
    margin-bottom: 30px;
  }

  img {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 30px;
  }

  .link {
    display: block;
    //font-size: 16px;
    color: #F17744;
    margin-bottom: 5px;

    &:hover {
      text-decoration: underline;
    }
  }

  .version {
    color: #7e7e7e;
    font-size: 11px;
    text-align: center;
  }
}
</style>
<template lang="pug">
RouterView(v-if="isWithinWebApp || (env === 'development')")
.not-mobile(v-else)
  .title
    | Forget the desktop.
    br
    | Mobile gaming is the future!
  .qr
    img(src="../img/qr_bot.png")
  a.link(href="https://t.me/snakechainio_bot") https://t.me/snakechainio_bot
  .version {{ versionNumber }}
</template>
