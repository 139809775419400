import {createStore} from 'vuex'

const store = createStore({
  state() {
    return {
      success: null,
      errors: null,
      player: null,
      config: null,
    }
  },
  mutations: {
    player(state, player) {
      state.player = player;
    },
    config(state, config) {
      state.config = config;
    },
    success(state, success) {
      state.success = success;
    },
    error(state, error) {
      state.errors = [error];
    },
    errors(state, errors) {
      state.errors = errors;
    },
  }
})

export default store
