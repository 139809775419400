<script setup>
import {onMounted, onUnmounted} from "vue";
import GameLayout from "@/Layouts/GameLayout.vue";
import {CheckIcon} from '@heroicons/vue/24/solid'
import {useStore} from "vuex";
import SpinnerInline from "@/Components/SpinnerInline.vue";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});
const store = useStore();
const player = $computed(() => store.state.player || {});
const config = $computed(() => store.state.config || {});

//computed mission where by id
const mission = $computed(() => {
  return config.missions.find(mission => mission.id === props.id);
});

const joined = $computed(() => player.missions?.[mission.id]?.joined_at);
const stepsFinished = $computed(() => {
  if (!joined) {
    return false;
  }

  return mission.steps.every((step, index) => {
    return player.missions?.[mission.id]?.steps?.[index]?.finished_at;
  });
});
const canFinishMission = $computed(() => {
  return joined && !player.missions?.[mission.id]?.finished_at && stepsFinished;
});

let loading = $ref(false);

onMounted(() => {
  Telegram.WebApp.BackButton.show();
  Telegram.WebApp.BackButton.onClick(back);
});

onUnmounted(() => {
  Telegram.WebApp.BackButton.hide();
  Telegram.WebApp.BackButton.offClick(back);
});

const joinMission = (id) => {
  loading = true;
  axios.post(route('game.join-mission', { id }))
      .then(() => {
        loading = false;
      });
};
const stepGo = (step, index) => {
  step.clicked = true;
  if (['tg_join', 'tg_premium', 'tg_link'].includes(step.type)) {
    if (Telegram.WebApp.initData) {
      Telegram.WebApp.openTelegramLink(step.link);
    }
    else {
      window.open(step.link, '_blank');
    }
  }
  else if (['x_follow', 'instagram'].includes(step.type)) {
    if (Telegram.WebApp.initData) {
      Telegram.WebApp.openLink(step.link);
    }
    else {
      window.open(step.link, '_blank');
    }
  }
};

const finishMissionStep = (id, step) => {
  // loading = true;
  axios.post(route('game.finish-mission-step', {
    id,
    step,
    init_data: Telegram.WebApp.initData,
  })).then(() => {
    // loading = false;
  });
};

const finishMission = (id) => {
  loading = true;
  axios.post(route('game.finish-mission', { id }))
      .then(() => {
        loading = false;
      });
};

function back() {
  history.back();
}

</script>
<style lang="scss" scoped>
.mission {
  padding: 30px 15px 20px;
  overflow-y: auto;
}

.mission-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 5px;
  text-align: center;
}

.mission-description {
  font-size: 14px;
  font-weight: 600;
  color: #5D656D;
  text-align: center;
  margin-bottom: 5px;
}

.reward {
  background: rgba(255, 255, 255, 0.05) url("../../img/coin_mini2_160.png") no-repeat center 35%;
  background-size: 80px;
  padding: 130px 10px 20px 10px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin: 30px auto 20px;
  width: 160px;
  text-align: center;
  position: relative;
  display: block;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url("../../img/coin_mini2_160.png") no-repeat center 35%;
    background-size: 70px;
    filter: blur(15px);
    z-index: -1;
  }

  .reward-title {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500;
  }

  .reward-amount {
    font-size: 18px;
    font-weight: 700;
  }

  &[disabled] {
    filter: grayscale(100%);
    opacity: 0.7;

    &:before {
      display: none;
    }
  }
}

.steps-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.steps {
  margin-bottom: 20px;
}

.step {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 20px 100px 20px 15px;
  margin-bottom: 10px;
  position: relative;

  .step-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }

  .step-description {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #5D656D;
    margin: 5px 0 0 0;
  }

  .step-btn {
    display: inline;
    right: 15px;
    position: absolute;
    margin: -18px 0 0 0;
    top: 50%;
    font-size: 14px;
    padding: 7px 20px;
    line-height: 22px;
  }

  .step-ready {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -10px;
    color: #fff;
    border-radius: 10px;
    background: radial-gradient(43.52% 43.52% at 52.76% 51.11%, #91C759 0%, #559B0C 100%);
    padding: 3px;
    stroke: #fff;
    stroke-width: 3;
  }
}

//.button {
//  background: #F57F13;
//  border: none;
//  border-radius: 10px;
//  color: #fff;
//  font-weight: 500;
//  padding: 10px 15px;
//  font-size: 15px;
//  display: block;
//  text-align: center;
//  margin-bottom: 15px;
//  width: 100%;
//
//  &:disabled {
//    filter: grayscale(100%);
//    opacity: 0.8;
//  }
//}

.finished-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}

.finished {
  width: 100px;
  height: 100px;
  background: radial-gradient(43.52% 43.52% at 52.76% 51.11%, #91C759 0%, #559B0C 100%);
  border-radius: 50px;
  margin: 0 auto;
  line-height: 100px;
  position: relative;
  stroke: #fff;
  stroke-width: 2;
  padding: 20px;
  display: block;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(43.52% 43.52% at 52.76% 51.11%, #91C759 0%, #559B0C 100%);
    border-radius: 50px;
    filter: blur(25px);
    z-index: -1;
  }
}
</style>
<template lang="pug">
GameLayout
  .mission
    .mission-title {{mission.title}}
    .mission-description {{mission.description}}

    .finished-wrapper(v-if="player.missions?.[mission.id]?.finished_at")
      .finished
        CheckIcon

    button.reward(v-else, :disabled="!canFinishMission", v-on:click="finishMission(mission.id)")
      //.reward-title Reward
      .reward-amount {{mission.reward.toLocaleString('ru')}}

    .text-center.mb-3
      button.button(v-if="!joined", v-on:click="joinMission(mission.id)", :disabled="loading")
        | Start Mission
        SpinnerInline(style="margin-left: 10px;" v-if="loading")

      button.button(v-if="canFinishMission", v-on:click="finishMission(mission.id)", :disabled="loading")
        | Get Reward
        SpinnerInline(style="margin-left: 10px;" v-if="loading")

    .steps-title Steps:
    .steps
      .step(v-for="(step, index) in mission.steps", v-on:click="stepGo(step, index)")
        .step-title {{step.title}}
        .step-description(v-if="step.description") {{step.description}}

        template(v-if="joined")
          CheckIcon.step-ready(v-if="player.missions?.[mission.id]?.steps?.[index]?.finished_at")
          button.button.step-btn(v-else-if="!step.clicked", v-on:click.stop.prevent="stepGo(step, index)") Run
          button.button.step-btn(v-else, v-on:click.stop.prevent="finishMissionStep(mission.id, index)") Check
</template>
