<script setup>
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/vue/24/solid'
import {onMounted, onUnmounted} from "vue";
import GameLayout from "@/Layouts/GameLayout.vue";
import {useStore} from "vuex";

const store = useStore();
const player = $computed(() => store.state.player);
const config = $computed(() => store.state.config);
let current = $ref(player.level);
const iconHeight = $computed(() => {
  return window.innerHeight - 330 + 'px';
});

onMounted(() => {
  Telegram.WebApp.BackButton.show();
  Telegram.WebApp.BackButton.onClick(back);
});

onUnmounted(() => {
  Telegram.WebApp.BackButton.hide();
  Telegram.WebApp.BackButton.offClick(back);
});

function back() {
  history.back();
}

const claimReward = (reward_id) => {
  axios.post(route('game.claim-reward'), {
    reward_id
  })
};
</script>
<style lang="scss" scoped>
.level {
  padding-top: 30px;
  text-align: center;
  user-select: none;
  padding-bottom: 30px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  margin: 0 0 10px;
}

.descr {
  font-size: 14px;
  color: rgba(248, 247, 246, 0.72);
  text-align: center;
  //margin-bottom: 20px;
}

.slider {
  position: relative;

  .left {
    width: 50px;
    position: absolute;
    top: 50%;
    left: 40px;
    padding: 10px;
    transform: translateY(-50%);
  }

  .right {
    width: 50px;
    position: absolute;
    top: 50%;
    right: 40px;
    padding: 10px;
    transform: translateY(-50%);
  }

  .icon {
    width: 350px;
    height: 350px;
    margin: 0 auto;
    position: relative;

    .shadow {
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      filter: blur(20px);
      position: absolute;
      z-index: -1;
    }

    .image {
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    @for $i from 0 to 11 {
      &.level_#{$i} {
        .shadow {
          background-image: url('../../img/level2/#{$i}.png');
        }

        .image {
          background-image: url('../../img/level2/#{$i}.png');
        }
      }
    }
  }
}

.from {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.4);
}

.progress {
  padding: 0 50px;

  .progress-value {
    font-size: 16px;
    font-weight: 600;
  }

  .progress-bar {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    margin-top: 10px;
    padding: 3px;

    .bar {
      height: 12px;
      border-radius: 6px;
      background: linear-gradient(270.04deg, #FFC632 -119.89%, #F38015 99.97%);
    }
  }
}

.reward {
  font-size: 24px;
  font-weight: 600;
  background: url("../../img/coin_mini2.png") no-repeat left center;
  background-size: 28px;
  padding-left: 34px;
}

.button.active {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.2);
}

</style>
<template lang="pug">
GameLayout
  div.level
    div.title {{ config?.levels[current]?.name }} Level
    div.descr Your number of shares determines your level.
    div.slider
      .icon(:class="'level_' + current", :style="{ height: iconHeight }")
        .shadow
        .image
      div.left(v-if="current !== 0", v-on:click="current -= (current === 0 ? 0 : 1)")
        ChevronLeftIcon
      div.right(v-if="current !== config?.levels.length - 1", v-on:click="current += (current === config?.levels.length - 1 ? 0 : 1)")
        ChevronRightIcon

    div(v-if="!config?.levels[current].reward || player.rewards['LEV' + current]")
      div.from From {{ config?.levels[current].from.toLocaleString('ru') }} shares
      button.button.active(disabled) Claimed
    .progress(v-else-if="player.shares < config?.levels[current].from")
      .progress-value
        | {{ player.shares.toLocaleString('ru') }} /
        | {{ config?.levels[current].from.toLocaleString('ru') }}
      .progress-bar
        .bar(:style="{width: (player.shares / config?.levels[current].from * 100) + '%'}")
    div(v-else)
      div(style="margin-bottom: 10px")
        span.reward {{ config?.levels[current].reward.toLocaleString('ru') }}
      button.button(v-on:click="claimReward('LEV' + current)") Claim

</template>
