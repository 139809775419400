<script setup>
import {ChevronRightIcon} from '@heroicons/vue/24/solid'
import {onMounted, onUnmounted} from "vue";
import {useStore} from "vuex";
import SmoothCounter from "@/Components/SmoothCounter.vue";

const props = defineProps({
  hideLevel: {
    type: Boolean,
    default: false
  }
});

const store = useStore();
const player = $computed(() => store.state.player || {});
const levels = $computed(() => store.state.config?.levels || []);
let energyInterval;

onMounted(() => {
  energyInterval = setInterval(energyTick, 1000);
});

onUnmounted(() => {
  clearInterval(energyInterval);
});

function energyTick() {
  if (player.energy < player.energy_level * 500) {
    player.energy += Math.min(player.charge_level, (player.energy_level * 500) - player.energy);
  } else if (player.autotap_till) {
    if(new Date(player.autotap_till) > Date.now()) {
      player.shares += player.charge_level;
    }
  }
}

</script>
<style lang="scss" scoped>
.shares-outer {
  text-align: center;
}

.shares {
  margin: 12px auto;
  font-size: 48px;
  line-height: 1;
  font-weight: 700;
  background: url("../../img/coin_mini2.png") no-repeat left center;
  background-size: 40px;
  display: inline-block;
  padding-left: 55px;
}

.level-outer {
  text-align: center;

  .level {
    font-size: 16px;
    color: #fff;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 40px auto;
    display: inline-block;
    padding-left: 40px;
    line-height: 40px;
    font-weight: 600;

    .icon {
      height: 18px;
      display: inline-block;
      margin-left: 2px;
    }

    @for $i from 0 to 11 {
      &.level_#{$i} {
        background-image: url('../../img/level2_80/#{$i}.png');
      }
    }
  }
}
</style>
<template lang="pug">
div
  div.shares-outer
    .shares
      SmoothCounter(:number="player.shares")

  div.level-outer(v-if="!props.hideLevel")
    RouterLink.level(:to="{ name: 'level' }", :class="'level_' + player.level")
      | {{ levels[player.level].name }} Level
      ChevronRightIcon.icon
</template>
