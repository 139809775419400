<script setup>
import ConfettiExplosion from "vue-confetti-explosion";
import {ref} from "vue";
import {useStore} from "vuex";

const store = useStore();
let player = $computed(() => store.state.player);

if(player.daily_reward) {
  player.shares -= player.daily_reward;
}

const close = () => {
  player.shares += player.daily_reward;
  player.daily_reward = 0;
}

</script>
<style lang="scss" scoped>
.daily-reward {
  background: #0E1012;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  padding: 50px 20px 20px;
  text-align: center;

  .congrats {
    font-size: 20px;
    font-weight: 800;
  }

  .confetti {
    position: absolute;
    left: 50%;
  }

  .days {
    font-size: 120px;
    font-weight: 800;
  }

  .descr {
    font-size: 20px;
    font-weight: 700;
    color: #5D656D;
    margin-bottom: 20px;
  }

  .reward {
    font-weight: 700;
    font-size: 30px;
    background: url("../../img/coin_mini2.png") no-repeat left center;
    background-size: 30px;
    padding-left: 40px;
  }

  .continue {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
  }
}
</style>
<template lang="pug">
.daily-reward(v-if="player.daily_reward")
  ConfettiExplosion.confetti
  .congrats Congratulations!
  .days {{ player.daily_days }}
  .descr days in a row
  div
    span.reward +{{ player.daily_reward.toLocaleString('ru') }}
  .button.continue(v-on:click="close") Continue
</template>
