<script setup>
import {onMounted} from "vue";
import GameLayout from "@/Layouts/GameLayout.vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import Spinner from "@/Components/Spinner.vue";

const store = useStore();
const router = useRouter();

onMounted(() => {
  const params = new URLSearchParams(location.search);

  setTimeout(() => {
    axios.post(route('game.login'), {
      init_data: Telegram.WebApp.initData,
      platform: Telegram.WebApp.platform,
      demo: params.get('demo') ?? null,
    }).then(() => {
      router.push(params.get('redirect') ?? { name: 'index' });
    });
  }, 100);
});
</script>
<template lang="pug">
GameLayout(:menu="false")
  Spinner
</template>
