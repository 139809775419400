import {createRouter, createWebHistory} from "vue-router"
import Index from "@/Pages/Index.vue";
import Referrals from "@/Pages/Referrals.vue";
import Tasks from "@/Pages/Tasks.vue";
import Boosts from "@/Pages/Boosts.vue";
import Stats from "@/Pages/Stats.vue";
import store from "./store.js";
import Login from "@/Pages/Login.vue";
import Mission from "@/Pages/Mission.vue";
import Level from "@/Pages/Level.vue";

const routes = [
  { path: '/', name: 'index', component: Index },
  { path: '/login', name: 'login', component: Login },
  { path: '/refs', name: 'referrals', component: Referrals },
  { path: '/tasks', name: 'tasks', component: Tasks },
  { path: '/boosts', name: 'boosts', component: Boosts },
  { path: '/stats', name: 'stats', component: Stats },
  { path: '/level', name: 'level', component: Level },
  { path: '/mission/:id', name: 'mission', props: true, component: Mission },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from) => {
  if (to.name !== 'login' && (!store.state.player || !store.state.config)) {
    return {
      name: 'login',
      query: { redirect: to.fullPath },
    }
  }
})

export default router
