import './bootstrap';
import '../css/app.css';

import {createApp} from 'vue';
import router from './router'
import store from './store';
import {ZiggyVue} from 'ziggy-js';
import * as Sentry from "@sentry/vue";
import packageJson from "../../package.json"
import App from "@/App.vue";

if (!Telegram.WebApp.isExpanded) {
  Telegram.WebApp.expand();
}
Telegram.WebApp.setBackgroundColor('#050506');
Telegram.WebApp.setHeaderColor('#050506');
Telegram.WebApp.isClosingConfirmationEnabled = true;
Telegram.WebApp.ready();

const app = createApp(App)
    .use(router)
    .use(store)
    .use(ZiggyVue);

app.config.globalProperties.versionNumber = packageJson.version;

Sentry.init({
  app,
  dsn: "https://7eba655e5a27ad3aec8653f9007d474b@o4507571166117888.ingest.us.sentry.io/4507588049240064",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount('#app');

