<script setup>
import GameLayout from "@/Layouts/GameLayout.vue";
import {onMounted, onUnmounted} from "vue";
import Spinner from "@/Components/Spinner.vue";
import SmoothCounter from "@/Components/SmoothCounter.vue";
import {useStore} from "vuex";

const store = useStore();
const config = $computed(() => store.state.config || {});
const player = $computed(() => store.state.player);

let data = $ref({
  shares: undefined,
  taps: undefined,
  users: undefined,
  daily: undefined,
  online: undefined,
  topPlayers: [],
});
let reloadTimeout = null;
let topPlayers = $ref([]);

onMounted(() => {
  reload();
  loadTopPlayers();
  reloadTimeout = setInterval(reload, 60000);
})
onUnmounted(() => {
  clearInterval(reloadTimeout);
});

const reload = () => {
  axios.get(route('game.stats'))
      .then((response) => {
        data = response.data;
      })
}

const loadTopPlayers = () => {
  axios.get(route('game.top-players'))
      .then((response) => {
        topPlayers = response.data.players;
      })
}
</script>
<style lang="scss" scoped>
.stats {
  //text-align: center;
  padding: 40px 10px 20px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: #5D656D;
  text-align: center;
}

.shares {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.card {
  float: left;
  width: 50%;
  padding: 5px;
  text-align: center;

  .inner {
    background: #FFFFFF0D;
    border: 1px solid rgba(255, 255, 255, 0.04);
    border-radius: 16px;
    padding: 29px 12px;
  }

  .title {
    margin-bottom: 5px;
    line-height: 20px;
  }

  .value {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    line-height: 29px;
  }
}

.title2 {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 5px;
}

.top-player {
  background: rgba(255, 255, 255, 0.05);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 15px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.05);

  .level {
    width: 60px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    margin-right: 5px;

    @for $i from 0 to 11 {
      &.level_#{$i} {
        background-image: url('../../img/level2_80/#{$i}.png');
      }
    }
  }

  .username {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .player-shares {
    font-size: 14px;
    font-weight: 500;
    background: url("../../img/coin_mini2.png") no-repeat left center;
    background-size: 14px;
    padding-left: 20px;
  }
}
</style>
<template lang="pug">
GameLayout
  .stats(v-if="data.shares !== undefined")
    .title Total Shares:
    .shares
      SmoothCounter(:number="data.shares")

    div
      .card
        .inner
          .title Total Taps:
          .value
            SmoothCounter(:number="data.taps")
      .card
        .inner
          .title Total Users:
          .value
            SmoothCounter(:number="data.users")
      .clear-both
    div
      .card
        .inner
          .title Daily Users:
          .value
            SmoothCounter(:number="data.daily")
      .card
        .inner
          .title Online Players:
          .value
            SmoothCounter(:number="data.online")
      .clear-both

    template(v-if="player.id === 2886118")
      .title2 Top Players:

      .top-player-list(v-if="topPlayers?.length")
        .top-player(v-for="topPlayer in topPlayers")
          .flex
            .level(:class="'level_' + topPlayer.level")
            div
              .username(v-if="topPlayer.username") @{{topPlayer.username}}
              .username(v-else) {{[topPlayer.first_name, topPlayer.last_name].join(' ').trim()}}
              .player-shares {{topPlayer.shares.toLocaleString('ru')}}
  Spinner(v-else)
</template>
