<script setup>
import Menu from "@/Partials/Menu.vue";
import {useStore} from "vuex";
import {watch} from "vue";

defineProps({
  menu: {
    type: Boolean,
    default: true,
  },
});

const store = useStore()
const success = $computed(() => store.state.success);
const errors = $computed(() => store.state.errors);

watch(() => store.state.success, (success) => {
  if(success) {
    setTimeout(() => {
      store.commit('success', null);
    }, 3000);
  }
}, { immediate: true });

watch(() => store.state.errors, (errors) => {
  if(errors && errors.length) {
    setTimeout(() => {
      store.commit('errors', null);
    }, 3000);
  }
}, { immediate: true });

</script>
<style lang="scss" scoped>
.game {
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  padding-bottom: 120px;
}
.menu {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.version {
  position: absolute;
  bottom: 13px;
  color: #7e7e7e;
  font-size: 11px;
  text-align: center;
  width: 100%;
}
.messages {
  position: fixed;
  bottom: 20px;
  left: 15px;
  right: 15px;
  background: rgba(0, 0, 0, 0.8);
  padding: 5px 10px;
  text-align: center;
  z-index: 1000;
  border-radius: 15px;
  font-size: 16px;
}
.success {
  color: #00cf00;
}
.error {
  color: #ff0000;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
<template lang="pug">
.game
  slot

  Menu.menu(v-if="menu")

  .version {{ versionNumber }}

  Transition
    .messages(v-if="success || (errors && errors.length)")
      .success(v-if="success")
        | {{ success }}
      .error(v-if="errors", v-for="error in errors" v-text="error")
</template>
