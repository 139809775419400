<script setup>
import Shares from "@/Partials/Shares.vue";
import {onMounted, onUnmounted} from "vue";
import GameLayout from "@/Layouts/GameLayout.vue";
import {XMarkIcon} from '@heroicons/vue/24/solid'
import {useStore} from "vuex";
import {useRouter} from "vue-router";

const store = useStore();
const router = useRouter();
const player = $computed(() => store.state.player);
const config = $computed(() => store.state.config);
let boost_interval;
let popup = $ref({});
let seconds = $ref(Date.now() / 1000);

onMounted(() => {
  boost_interval = setInterval(tickSeconds, 1000);
  tickSeconds();
});

onUnmounted(() => {
  clearInterval(boost_interval);
});

const confirmBoost = (type) => {
  if (type === 'sprint') {
    popup = {
      title: 'Sprint',
      descr: 'How fast can you tap? Get increased tap income by x' + config.boosts.sprint.multiplier + ' for ' + config.boosts.sprint.duration + ' seconds and find out! BTW, your energy will not be used.',
      price: 'Free',
      show: true,
      loading: false,
      callback: () => {
        popup.show = false;

        axios.post(route('game.boost'), {
          type: type,
        }).then(() => {
          router.push({ name: 'index' });
        });
      }
    };
  }
  else if (type === 'marathon') {
    popup = {
      title: 'Marathon',
      descr: 'Wasted all your energy? Get full energy and keep tapping!',
      price: 'Free',
      show: true,
      loading: false,
      callback: () => {
        popup.show = false;

        axios.post(route('game.boost'), {
          type: type,
        }).then(() => {
          router.push({ name: 'index' });
        });
      }
    };
  }
}

const confirmUpgrade = (type) => {
  if (type === 'tap') {
    popup = {
      title: 'Tap blessing',
      descr: 'Increase your tap income per each tap.<br /> Each level increases your tap income by +1.',
      price: config.tap_levels[player.tap_level].price.toLocaleString('ru'),
      show: true,
      loading: false,
      callback: () => {
        popup.show = false;

        axios.post(route('game.upgrade'), {
          type: type,
        });
      }
    };
  }
  else if (type === 'energy') {
    popup = {
      title: 'Energy blessing',
      descr: 'Increase your total energy.<br /> Each level increases your total energy by +500.',
      price: config.energy_levels[player.energy_level].price.toLocaleString('ru'),
      show: true,
      loading: false,
      callback: () => {
        popup.show = false;

        axios.post(route('game.upgrade'), {
          type: type,
        });
      }
    };
  }
  else if (type === 'charge') {
    popup = {
      title: 'Charge blessing',
      descr: 'Increase your charge speed.<br /> Each level increases your charge speed by +1 per second.',
      price: config.charge_levels[player.charge_level].price.toLocaleString('ru'),
      show: true,
      loading: false,
      callback: () => {
        popup.show = false;

        axios.post(route('game.upgrade'), {
          type: type,
        });
      }
    };
  }
  else if (type === 'autotap') {
    let duration;
    if (config.autotap.duration > 3600) {
      duration = Math.floor(config.autotap.duration / 3600) + ' hours';
    }
    else {
      duration = Math.floor(config.autotap.duration / 60) + 'm';
    }

    popup = {
      title: 'Auto-tap',
      descr: 'Tired of tapping?<br /> Get auto-tap for ' + duration + ' and relax!<br /> You will receive shares according to your charge level every second.',
      price: config.autotap.price.toLocaleString('ru'),
      show: true,
      loading: false,
      callback: () => {
        popup.show = false;

        axios.post(route('game.upgrade'), {
          type: type,
        });
      }
    };
  }
}

const sprint_expire_at = $computed(() => {
  if (!player.boosts?.sprint?.count) {
    const secs = player.boosts?.sprint?.restore_at - seconds;
    if (secs > 3600) {
      return Math.floor(secs / 3600) + 'h ' + Math.floor(secs % 3600 / 60) + 'm ' + Math.floor(secs % 60) + 's';
    }
    else {
      return Math.floor(secs / 60) + 'm ' + Math.floor(secs % 60) + 's';
    }
  }

  return null;
});

const marathon_expire_at = $computed(() => {
  if (!player.boosts?.marathon?.count) {
    const secs = player.boosts?.marathon?.restore_at - seconds;
    if (secs > 3600) {
      return Math.floor(secs / 3600) + 'h ' + Math.floor(secs % 3600 / 60) + 'm ' + Math.floor(secs % 60) + 's';
    }
    else {
      return Math.floor(secs / 60) + 'm ' + Math.floor(secs % 60) + 's';
    }
  }

  return null;
});

function tickSeconds() {
  seconds = Date.now() / 1000;
}

</script>
<style lang="scss" scoped>
.boosts {
  padding: 20px 10px 0;
  position: relative;
  height: 100%;
}

.title {
  text-align: center;
  font-weight: 500;
}

.boosts-list {
  padding: 0 10px 90px;
  text-align: center;
  overflow-y: auto;
  height: 100%;
}

.daily-boosts {
  margin-bottom: 30px;
}

.daily-boost {
  background-color: rgba(255, 255, 255, 0.05);
  background-repeat: no-repeat;
  background-position: center 15px;
  background-size: 50px;
  width: 50%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 75px 10px 10px;
  margin: 5px 5px;
  font-size: 13px;
  text-align: center;

  .name {
    font-size: 16px;
    font-weight: 700;
  }

  .countdown {
    font-size: 14px;
  }

  &.sprint {
    background-image: url("../../img/sprint.png");
  }

  &.marathon {
    background-image: url("../../img/marathon.png");
  }

  &[disabled] {
    opacity: 0.9;
    color: #bdbdbd;
    filter: grayscale(100%);
  }
}

.upgrades {
  padding: 0 5px;
}

.upgrade {
  background-color: rgba(255, 255, 255, 0.05);
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 40px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 10px 15px 10px 70px;
  margin: 0 0 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  display: block;
  width: 100%;
  position: relative;

  .name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .cost-level {

  }

  .cost {
    padding-left: 25px;
    background: url("../../img/coin_mini2.png") no-repeat left center;
    background-size: contain;
  }

  .addval {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #F38015;
    //background: -webkit-linear-gradient(270deg, #F38015, #FFC632);
    //background-clip: text;
    //-webkit-text-fill-color: transparent;
  }

  &.tap {
    background-image: url("../../img/tap.png");
  }

  &.energy {
    background-image: url("../../img/energy.png");
  }

  &.charge {
    background-image: url("../../img/charge.png");
  }

  &.autotap {
    background-image: url("../../img/autotap.png");
  }

  &[disabled] {
    opacity: 0.9;
    color: #bdbdbd;
    filter: grayscale(100%);
  }
}

.popup {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px 20px 40px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);

  .close {
    width: 40px;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    stroke: #fff;
    stroke-width: 2;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .descr {
    font-size: 17px;
    margin-bottom: 10px;
    opacity: 0.8;
  }

  .price {
    background: url("../../img/coin_mini2.png") no-repeat left center;
    background-size: 20px;
    padding-left: 25px;
    display: inline-block;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 14px;
  }

  .button {
    display: block;
    width: 100%;
  }
}
</style>
<template lang="pug">
GameLayout
  .boosts
    //.title Your balance

    Shares(:hide-level="true")

    .boosts-list
      //h2.mb-2 Daily boosters:

      .flex.w-full.daily-boosts
        button.daily-boost.sprint(v-on:click="confirmBoost('sprint')", :disabled="!player.boosts?.sprint?.count")
          .name Sprint
          .count(v-if="player.boosts?.sprint?.count") {{player.boosts?.sprint?.count}}/{{config.boosts?.sprint?.count}}
          .countdown(v-else) {{ sprint_expire_at }}
        button.daily-boost.marathon(v-on:click="confirmBoost('marathon')", :disabled="!player.boosts?.marathon?.count")
          .name Marathon
          .count(v-if="player.boosts?.marathon?.count") {{player.boosts?.marathon?.count}}/{{config.boosts?.marathon?.count}}
          .countdown(v-else) {{ marathon_expire_at }}

      //h2.mb-2 Boosters:

      .upgrades
        button.upgrade.tap(v-on:click="confirmUpgrade('tap')", :disabled="!config.tap_levels[player.tap_level] || player.shares < config.tap_levels[player.tap_level].price")
          .name Tap Power
          .cost-level
            span(v-if="config.tap_levels[player.tap_level]").cost {{config.tap_levels[player.tap_level]?.price?.toLocaleString('ru')}}
            span.level(v-else) Max
            span.level &nbsp;&nbsp;|&nbsp;&nbsp;
            span.level Level {{player.tap_level}}
          .addval(v-if="player.tap_level > 1") +{{player.tap_level-1}}

        button.upgrade.energy(v-on:click="confirmUpgrade('energy')", :disabled="!config.energy_levels[player.energy_level] || player.shares < config.energy_levels[player.energy_level].price")
          .name Energy Volume
          .cost-level
            span(v-if="config.energy_levels[player.energy_level]").cost {{config.energy_levels[player.energy_level]?.price?.toLocaleString('ru')}}
            span.level(v-else) Max
            span.level &nbsp;&nbsp;|&nbsp;&nbsp;
            span.level Level {{player.energy_level}}
          .addval(v-if="player.energy_level > 1") +{{(player.energy_level - 1) * 500}}

        button.upgrade.charge(v-on:click="confirmUpgrade('charge')", :disabled="!config.charge_levels[player.charge_level] || player.shares < config.charge_levels[player.charge_level].price")
          .name Charge Speed
          .cost-level
            span(v-if="config.charge_levels[player.charge_level]").cost {{config.charge_levels[player.charge_level]?.price?.toLocaleString('ru')}}
            span.level(v-else) Max
            span.level &nbsp;&nbsp;|&nbsp;&nbsp;
            span.level Level {{player.charge_level}}
          .addval(v-if="player.charge_level > 1") +{{player.charge_level - 1}}/s

        button.upgrade.autotap(v-on:click="confirmUpgrade('autotap')", :disabled="player.autotap_till || player.shares < config.autotap.price")
          .name Auto-tap
          .cost-level
            span.cost {{config.autotap.price.toLocaleString('ru')}}

  Transition(
    enter-active-class="transition ease-in-out"
    enter-from-class="opacity-0"
    leave-active-class="transition ease-in-out"
    leave-to-class="opacity-0")

    .popup(v-if="popup.show")
      .close(v-on:click="popup.show = false")
        XMarkIcon
      .title {{ popup.title }}
      .descr(v-html="popup.descr")
      .price {{ popup.price }}
      button.button(v-if="popup.callback", v-on:click="popup.callback", :disabled="popup.loading")
        span(v-if="popup.loading") Getting...
        span(v-else) Get

</template>
