<script setup>
import {onMounted} from "vue";
import {ChevronRightIcon} from '@heroicons/vue/24/solid'
import GameLayout from "@/Layouts/GameLayout.vue";
import {useStore} from "vuex";
import Spinner from "@/Components/Spinner.vue";

const store = useStore();
const player = $computed(() => store.state.player || {});
const config = $computed(() => store.state.config || {});
const referral_link = 'https://t.me/snakechainio_bot?start=r_' + player.id;

let loading = $ref(true);
let copied = $ref(false);
let referrals = $ref();
let next_page_url = $ref(null);

const referralToClipboard = () => {
  navigator.clipboard.writeText(referral_link);
  copied = true;
}

const shareReferral = () => {
  const shareText = 'Join me in the game! Let\'s get rich together!';
  const shareLink = `https://t.me/share/url?url=${encodeURI(referral_link)}&text=${encodeURI(shareText)}`;

  Telegram.WebApp.openTelegramLink(shareLink);
}

const openReferral = (referral) => {
  Telegram.WebApp.openTelegramLink(`https://t.me/${referral.username}`);
}

onMounted(() => {
  loading = true;
  axios.get(route('game.referrals'))
      .then(response => {
        loading = false;
        referrals = response.data.referrals;
        next_page_url = response.data.next_page_url;
      }).catch(() => {});
})

function loadMore() {
  loading = true;
  axios.get(next_page_url)
      .then(response => {
        loading = false;
        referrals = [...referrals, ...response.data.referrals];
        next_page_url = response.data.next_page_url;
      }).catch(() => {});
}

</script>
<style lang="scss" scoped>
.referrals {
  padding: 50px 20px 20px;
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.head {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.invite {
  background: #FFFFFF0D;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 12px;
  margin-bottom: 35px;

  .left-side {
    width: 190px;
    flex-grow: 1;

    .title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .ref-link {
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 500;
    }
  }

  .share-btn {
    flex: none;
  }
}

.copied {
  font-size: 16px;
  font-weight: 600;
  color: #559B0C;
  position: absolute;
  background: rgba(255, 255, 255, 0.05);
  padding: 8px 16px;
  border-radius: 100px;
  top: 10px;
  left: 50%;
  margin-left: -59px;
}

.total-share {
  font-size: 14px;
  color: #F38015;
}

.my-referrals-text {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.referral {
  background: rgba(255, 255, 255, 0.05);
  margin-bottom: 10px;
  padding: 12px;
  border-radius: 15px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.05);

  .username {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 7px;
  }

  .level-shares {
    font-size: 14px;
    font-weight: 500;

    .level {
      padding-left: 24px;
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: left center;
      padding-right: 7px;

      @for $i from 0 to 11 {
        &.level_#{$i} {
          background-image: url('../../img/level2_80/#{$i}.png');
        }
      }
    }

    .shares {
      background: url("../../img/coin_mini2.png") no-repeat left center;
      background-size: 14px;
      padding-left: 20px;
      margin-left: 7px;
    }
  }

  .right-icon {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -8px;
  }

  .rewards {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 35px;
    color: #F38015;
  }
}

.no-refs {
  font-size: 16px;
  color: #c1c1c1;
}

.loading {
  height: auto;
  margin-top: 20px;
}

</style>
<template lang="pug">
GameLayout
  .referrals
    .head {{player.ref_count?.toLocaleString('ru') || 0}} Referrals

    .copied(v-show="copied") Link copied

    .invite
      .flex
        .left-side
          .title Your invite link:
          .ref-link(v-on:click="referralToClipboard") {{referral_link}}

        .button.share-btn(v-on:click="shareReferral") Share

    .mb-3
      span.my-referrals-text
        | My Referrals:&nbsp;
        span.total-share +{{player.ref_in?.toLocaleString('ru') || 0}}

    .referrals-list(v-if="referrals?.length")
      .referral(v-for="referral in referrals", v-on:click="openReferral(referral)")
        .username {{[referral.first_name, referral.last_name].join(' ').trim()}}
        .level-shares
          span.level(:class="'level_' + referral.level") {{config.levels[referral.level]?.name}}
          | |
          span.shares {{referral.shares.toLocaleString('ru')}}
        .rewards +{{referral.ref_out?.toLocaleString('ru') || 0}}
        ChevronRightIcon.right-icon
    .no-refs(v-else-if="!loading")
      | Invite your friends to the game and get rewards!
      br
      | Share your link to get some!
      br
      | Progress of your referrals will be shown here.

    Spinner.loading(v-if="loading")
    .text-center
      button.button(v-if="next_page_url && !loading", v-on:click.prevent="loadMore") Load more
</template>
